import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { ShoppingConsumer } from "../layouts/index"

const ShoppingItems = ({ intl }) => (
  <ShoppingConsumer>
    {({ service, handleRemove }) => (
      <>
        {service.length > 0 && (
          <article className="message is-warning is-shadow">
            <div className="message-body">
              <div className="field is-grouped is-grouped-multiline">
                {service.length > 0 &&
                  service.map((item, i) => (
                    <div key={i} className="control mr-1">
                      <div
                        className="tags are-medium has-addons"
                        onClick={() => handleRemove(item.id)}
                      >
                        <a className="tag is-warning is-shadow">{item.name}</a>
                        <a className="tag has-background-white is-delete is-shadow"></a>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </article>
        )}
      </>
    )}
  </ShoppingConsumer>
)

export default injectIntl(ShoppingItems)
