import React from "react"
import { injectIntl, useIntl } from "gatsby-plugin-intl"
import { Formik, Form, Field } from "formik"
import { registerLocale } from "react-datepicker"
import axios from "axios"
import classNames from "classnames"
import * as Yup from "yup"

import { ru } from "date-fns/locale"

import PhoneInputField from "./PhoneInputField"
import { ShoppingConsumer } from "../layouts/index"

import "react-datepicker/dist/react-datepicker.css"

registerLocale("ru", ru)

const FormFirst = () => {
  const intl = useIntl()

  const ContactSchema = Yup.object().shape({
    name: Yup.string()
      .max(
        50,
        intl.formatMessage({
          id: `contact.required-50`,
        })
      )
      .required(
        intl.formatMessage({
          id: `contact.required`,
        })
      ),
    phone: Yup.string().required(
      intl.formatMessage({
        id: `contact.required`,
      })
    ),
  })
  return (
    <ShoppingConsumer>
      {({
        handleButton,
        handleService,
        handleSpecialty,
        service,
        specialty,
        handleQuantity,
        handleName,
      }) => (
        <Formik
          initialValues={{
            name: "",
            quantity: "",
            phone: "",
          }}
          validationSchema={ContactSchema}
          onSubmit={(values, actions) => {
            const msg = encodeURIComponent(
              "<b>----------Ф.И.О----------\n\n</b>" +
                `${values.name}\n\n` +
                "<b>----------Номер телефона----------\n\n</b>" +
                `${values.phone}\n\n` +
                "<b>----------Услуга----------\n\n</b>" +
                `${service.map(item => `${item.name}`)} \n\n` +
                "<b>----------Специализация----------\n\n</b>" +
                `${specialty.map(item => `${item.name}`)} \n\n`
            )
            const url = `https://api.telegram.org/bot1696890830:AAG04kbKyFPw6oT8FpNq86RQSqbNuCc1gkQ/sendMessage?chat_id=234947775&parse_mode=HTML&text=${msg}`
            axios
              .get(url)
              .then(function(response) {
                actions.setStatus({ msg: "Сообщение успешно отправлено" })
                setTimeout(actions.resetForm, 3000)
              })
              .catch(function(error) {
                console.log(error)
              })

            handleButton()
          }}
        >
          {({ errors, touched, status, values, handleChange }) => {
            const handleNameInput = e => {
              console.log(e.target.value)
              handleName(e.target.value)
              handleChange(e)
            }

            return (
              <Form className="container has-family-pt-sans">
                <div className="field mb-1">
                  <label className={classNames("label")}>
                    {service.length >= 1 ? (
                      <span className="has-text-primary">
                        {intl.formatMessage({
                          id: `order-page.desc-5`,
                        })}
                      </span>
                    ) : (
                      intl.formatMessage({
                        id: `order-page.desc-1`,
                      })
                    )}
                  </label>

                  <div class="buttons">
                    {service.filter(e => e.id === "item-1").length >
                    0 ? null : (
                      <div
                        onClick={() =>
                          handleService({ val: "Стационар", id: "item-1" })
                        }
                        className="button is-primary has-text-weight-bold"
                      >
                        Стационар
                      </div>
                    )}
                    {service.filter(e => e.id === "item-2").length >
                    0 ? null : (
                      <div
                        onClick={() =>
                          handleService({
                            val: intl.formatMessage({
                              id: `order-page.desc-2`,
                            }),
                            id: "item-2",
                          })
                        }
                        className="button is-primary has-text-weight-bold"
                      >
                        {intl.formatMessage({
                          id: `order-page.desc-2`,
                        })}
                      </div>
                    )}
                  </div>
                </div>
                {service.filter(e => e.id === "item-2").length > 0 ? (
                  <div className="field mb-1">
                    <label className={classNames("label")}>
                      {specialty.length >= 1 ? (
                        <span className="has-text-primary">
                          {intl.formatMessage({
                            id: `order-page.desc-7`,
                          })}
                        </span>
                      ) : (
                        intl.formatMessage({
                          id: `order-page.desc-6`,
                        })
                      )}
                    </label>

                    <div class="buttons">
                      {specialty.filter(e => e.id === "item-1").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Акушерства-гинекология",
                              id: "item-1",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Акушерства-гинекология
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-2").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Терапия",
                              id: "item-2",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Терапия
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-3").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Кардиология",
                              id: "item-3",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Кардиология
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-4").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Лаборатория",
                              id: "item-4",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Лаборатория
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-5").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Онкология",
                              id: "item-5",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Онкология
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-6").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Торакальная хирургия",
                              id: "item-6",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Торакальная хирургия
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-7").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Сосудистая хирургия",
                              id: "item-7",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Сосудистая хирургия
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-8").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Урология",
                              id: "item-8",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Урология
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-9").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Травматология-ортопедия",
                              id: "item-9",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Травматология-ортопедия
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-10").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Неврология",
                              id: "item-10",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Неврология
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-11").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Общая хирургия",
                              id: "item-11",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Общая хирургия
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-12").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Оториноларингология",
                              id: "item-12",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Оториноларингология
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-13").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Функциональная диагностика",
                              id: "item-13",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Функциональная диагностика
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-14").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Радиология",
                              id: "item-14",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Радиология
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-15").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Реанимация",
                              id: "item-15",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Реанимация
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-16").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Физиотерапия",
                              id: "item-16",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Физиотерапия
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-17").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Рентгенография",
                              id: "item-17",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Рентгенография
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-18").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Мини операции",
                              id: "item-18",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Мини операции
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-19").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "Бронхоскопия",
                              id: "item-19",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          Бронхоскопия
                        </div>
                      )}
                      {specialty.filter(e => e.id === "item-20").length >
                      0 ? null : (
                        <div
                          onClick={() =>
                            handleSpecialty({
                              val: "УЗИ",
                              id: "item-20",
                            })
                          }
                          className="button is-primary has-text-weight-bold"
                        >
                          УЗИ
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}

                <div className="field mb-1">
                  <div className="control">
                    <label className={classNames("label")}>
                      {intl.formatMessage({
                        id: `order-page.desc-3`,
                      })}
                    </label>
                    <Field
                      name="name"
                      onChange={handleNameInput}
                      id="name"
                      className={classNames(
                        "input is-medium has-family-pt-sans is-size-6-mobile"
                      )}
                    />
                    {errors.name && touched.name ? (
                      <p className="is-size-7 has-text-primary has-text-weight-semibold">
                        {errors.name}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="field mb-1">
                  <div className="control">
                    <label className={classNames("label")}>
                      {intl.formatMessage({
                        id: `order-page.desc-4`,
                      })}
                    </label>
                    <Field
                      className={classNames(
                        "input is-medium has-family-pt-sans"
                      )}
                      name="phone"
                      component={PhoneInputField}
                    />
                    {errors.phone && touched.phone ? (
                      <p className="is-size-7 has-text-primary has-text-weight-semibold">
                        {errors.phone}
                      </p>
                    ) : null}
                  </div>
                </div>

                {status && status.msg && (
                  <p className="has-text-weight-medium has-text-primary mb-1 has-text-centered">
                    {intl.formatMessage({
                      id: `contact.success`,
                    })}
                  </p>
                )}
                {values.name &&
                  values.phone &&
                  service.length > 0 &&
                  specialty.length > 0 && (
                    <button
                      type="submit"
                      className="button is-size-6-mobile has-text-weight-bold is-normal is-warning mb-2 is-uppercase"
                    >
                      {intl.formatMessage({
                        id: `button.send`,
                      })}
                    </button>
                  )}
              </Form>
            )
          }}
        </Formik>
      )}
    </ShoppingConsumer>
  )
}

export default injectIntl(FormFirst)
