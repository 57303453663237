import React from "react"
import classNames from "classnames"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { useToggle } from "../hooks/use-toggle"

const NavBar = ({ intl, location }) => {
  const [val, toggleMenu] = useToggle(false)
  return (
    <>
      <nav
        className="navbar is-primary is-shadow"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link
              className="navbar-item has-text-white is-size-5-desktop has-text-weight-bold mr-2 has-family-nunito"
              to="/"
            >
              FANOMED
            </Link>
            <div className="navbar-item">
              <Link
                className="button is-size-7 is-rounded is-uppercase has-text-weight-bold is-white is-outlined"
                to="/booking"
              >
                {intl.formatMessage({
                  id: `header.nav.item-6`,
                })}
              </Link>
            </div>

            <a
              role="button"
              className={
                val ? "navbar-burger burger is-active" : "navbar-burger burger"
              }
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasic"
              onClick={toggleMenu}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div className={val ? "navbar-menu is-active" : "navbar-menu"}>
            <div className="navbar-end is-uppercase is-size-7">
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link has-text-weight-bold">
                  {intl.formatMessage({ id: "header.nav.item-1" })}
                </a>
                <div className="navbar-dropdown is-size-7 has-text-weight-bold">
                  <Link to="/about" className="navbar-item">
                    {intl.formatMessage({
                      id: "header.about.item-1",
                    })}
                  </Link>
                  <Link to="/chief" className="navbar-item">
                    {intl.formatMessage({
                      id: "header.about.item-2",
                    })}
                  </Link>
                  <Link to="/doctors" className="navbar-item">
                    {intl.formatMessage({
                      id: "header.about.item-3",
                    })}
                  </Link>
                </div>
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link has-text-weight-bold">
                  {intl.formatMessage({ id: "header.nav.item-2" })}
                </a>
                <div className="navbar-dropdown is-size-7 has-text-weight-bold">
                  <Link to="/polyclinic" className="navbar-item">
                    {intl.formatMessage({
                      id: "header.service.item-1",
                    })}
                  </Link>
                  <Link to="/hospital" className="navbar-item">
                    {intl.formatMessage({
                      id: "header.service.item-2",
                    })}
                  </Link>
                  <Link to="/lab" className="navbar-item">
                    {intl.formatMessage({
                      id: "header.service.item-3",
                    })}
                  </Link>
                </div>
              </div>
              <Link
                to="/promotion"
                className="navbar-item is-size-7 has-text-weight-bold"
                activeClassName="is-active"
              >
                {intl.formatMessage({
                  id: `header.nav.item-3`,
                })}
              </Link>
              <Link
                to="/news"
                className="navbar-item is-size-7 has-text-weight-bold"
                activeClassName="is-active"
              >
                {intl.formatMessage({
                  id: `header.nav.item-4`,
                })}
              </Link>
              <Link
                to="/contact"
                className="navbar-item is-size-7 has-text-weight-bold mr-2"
                activeClassName="is-active"
              >
                {intl.formatMessage({
                  id: `header.nav.item-5`,
                })}
              </Link>
              <div className="navbar-item">
                <div className="field is-grouped">
                  <p className="control">
                    <a
                      href="tel:+998742278297"
                      className="button is-rounded has-text-weight-bold is-size-7 is-warning mr-1"
                    >
                      {intl.formatMessage({
                        id: `footer.col_1.phone`,
                      })}
                    </a>
                    <a
                      href="tel:+998934285558"
                      className="button is-rounded has-text-weight-bold is-size-7 is-warning"
                    >
                      {intl.formatMessage({
                        id: `footer.col_1.mobile-phone`,
                      })}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default injectIntl(NavBar)
