import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import SEO from "../components/SEO/index"
import { Section } from "../components/CompoundComponents/Section"
import { Columns, Column } from "../components/CompoundComponents/Columns"
import { Box } from "../components/CompoundComponents/Box"
import FormFirst from "../components/form"
import HeroBooking from "../components/Sections/HeroBooking"
import StyledBackgroundBooking from "../components/BackgroundBooking"
import ShoppingItems from "../components/ShoppingItems"
import ShoppingSpecialty from "../components/ShoppingSpecialty"
import Schedule from "../components/Schedule"

import { ShoppingConsumer } from "../layouts/index"

const BookingPage = ({ location, intl }) => {
  return (
    <>
      <SEO title="Fanomed clinics" description="Fanomed clinics" />
      <StyledBackgroundBooking>
        <HeroBooking location={location} data="header.service.item-19" />
      </StyledBackgroundBooking>
      <Section white>
        <Columns>
          <Column is8>
            <Box white>
              <FormFirst />
            </Box>
          </Column>
          <Column is4>
            <Schedule />
            <ShoppingConsumer>
              {({ service, specialty, name, phone }) => (
                <>
                  {name ||
                  phone ||
                  service.length > 0 ||
                  specialty.length > 0 ? (
                    <p className="mb-1 is-size-5 is-uppercase has-text-weight-bold">
                      {intl.formatMessage({
                        id: `order-page.desc-8`,
                      })}
                    </p>
                  ) : null}

                  {service.length > 0 ? <ShoppingItems /> : null}

                  {specialty.length ? <ShoppingSpecialty /> : null}
                  {name ? (
                    <article className="message is-warning is-shadow">
                      <div className="message-header">
                        {intl.formatMessage({
                          id: `order-page.desc-3`,
                        })}
                      </div>
                      <div className="message-body">{name}</div>
                    </article>
                  ) : null}
                  {phone ? (
                    <article className="message is-warning is-shadow">
                      <div className="message-header">
                        <p>
                          {intl.formatMessage({
                            id: `order-page.desc-4`,
                          })}
                        </p>
                      </div>
                      <div className="message-body">{phone}</div>
                    </article>
                  ) : null}
                </>
              )}
            </ShoppingConsumer>
          </Column>
        </Columns>
      </Section>
    </>
  )
}

export default injectIntl(BookingPage)
