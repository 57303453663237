import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"

const Schedule = ({ intl }) => (
  <div className="box">
    <article className="message is-warning">
      <div className="message-header">
        <p>{intl.formatMessage({ id: "schedule.item-9" })}</p>
      </div>
      <div className="message-body">
        <ul className="mb-1 has-family-nunito">
          <li>{intl.formatMessage({ id: "schedule.item-1" })} - 08.30-17.00</li>
          <li>{intl.formatMessage({ id: "schedule.item-2" })} - 08.30-17.00</li>
          <li>{intl.formatMessage({ id: "schedule.item-3" })} - 08.30-17.00</li>
          <li>{intl.formatMessage({ id: "schedule.item-4" })} - 08.30-17.00</li>
          <li>{intl.formatMessage({ id: "schedule.item-5" })} - 08.30-17.00</li>
          <li>{intl.formatMessage({ id: "schedule.item-6" })} - 08.30-17.00</li>
          <li>
            {intl.formatMessage({ id: "schedule.item-7" })} -{" "}
            {intl.formatMessage({ id: "schedule.item-8" })}
          </li>
        </ul>
        <Link
          className="button is-fullwidth is-rounded justify-content-center is-uppercase is-warning has-text-weight-bold"
          to="/booking"
        >
          {intl.formatMessage({
            id: `header.nav.item-6`,
          })}
        </Link>
      </div>
    </article>
  </div>
)

export default injectIntl(Schedule)
