import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { Columns, Column } from "../CompoundComponents/Columns"
import {
  Hero,
  HeroTitle,
  HeroBody,
  HeroHead,
  HeroSubTitle,
} from "../CompoundComponents/Hero"
import NavBar from "../navbar"

const HeroBooking = ({ intl, location }) => {
  return (
    <>
      <Hero>
        <HeroHead>
          <NavBar location={location} />
        </HeroHead>
        <HeroBody>
          <Columns vcentered>
            <Column is12 centered>
              <HeroTitle>
                {" "}
                {intl.formatMessage({
                  id: `header.nav.item-6`,
                })}
              </HeroTitle>
              <HeroSubTitle>
                {intl.formatMessage({
                  id: `order-page.desc-9`,
                })}
              </HeroSubTitle>
            </Column>
          </Columns>
        </HeroBody>
      </Hero>
    </>
  )
}

export default injectIntl(HeroBooking)
